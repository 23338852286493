import { Button, Col, Divider, Row, Typography } from "antd";

import RightBar from "../components/RightBar";
import SideBar from "../components/SideBar";
import React from "react";
import bars1 from "../images/bars1.png";
import bars2 from "../images/bars2.png";
import screws from "../images/screws.png";

import "../styles/main.css";
import HowItWorksCard from "../components/HowItWorksCard";
import ImageGroup from "../components/ImageGroup";
import {
  CloudUploadOutlined,
  SelectOutlined,
  ToolOutlined,
  ShoppingOutlined,
  EyeOutlined,
  SendOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { Title } = Typography;
const BuyerProcess = ({ handleClick, home, buyer, seller, ourworks }) => {
  let history = useHistory();

  const title = [
    "View buyer RFQs",
    "Send Quotations",
    "Chat with Buyer",
    "Sell your Products",
  ];
  const desc = [
    "Browse through Requests for Quotations from customers belonging to your category OR view all RFQ's by manually filtering according to category.",
    "Review design files uploaded by Buyers. You can send your Quotation File in response to an RFQ. Wait for the buyer to reply to it!",
    "With our Live Chat feature you can directly communicate details regarding your manufacturing process and prices to the Buyer.",
    "Get notified once your quotation is accepted and the order is confirmed. Selling your products has never been easier.",
  ];
  return (
    <div
      className="sellerprocess"
      ref={seller}
      id="seller"
      style={{ marginTop: "120px", marginBottom: "100px" }}
    >
      <Row type="flex" justify="center">
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            fontFamily: "Oswald",
          }}
        >
          <h1
            style={{
              color: "white",
              fontWeight: "1000",
              fontSize: "40px",
              fontFamily: "Oswald",
              // fontStretch: "condensed",
            }}
          >
            How to accept RFQs?
          </h1>
        </div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Divider style={{ borderColor: "#f7cb0c" }} />
        </div>
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col
          xl={{ span: 4, order: 1 }}
          // lg={{ span: 4, order: 1 }}
          lg={0}
          xs={0}
          sm={0}
          md={0}
        >
          <SideBar />
        </Col>

        <Col
          xl={{ span: 4, order: 3 }}
          lg={{ span: 6, order: 1 }}
          xs={{ order: 2, span: 24 }}
          sm={{ order: 2, span: 12 }}
          md={{ span: 12, order: 1 }}
        >
          <ImageGroup
            title={title[0]}
            desc={desc[0]}
            icon={EyeOutlined}
            index={1}
          />
        </Col>
        <Col
          xl={{ span: 4, order: 3 }}
          lg={{ span: 6, order: 2 }}
          xs={{ order: 2, span: 24 }}
          sm={{ order: 2, span: 12 }}
          md={{ span: 12, order: 2 }}
        >
          <ImageGroup
            title={title[1]}
            desc={desc[1]}
            icon={SendOutlined}
            index={2}
          />
        </Col>
        <Col
          xl={{ span: 4, order: 4 }}
          lg={{ span: 6, order: 3 }}
          xs={{ order: 3, span: 24 }}
          sm={{ order: 3, span: 12 }}
          md={{ span: 12, order: 3 }}
        >
          <ImageGroup
            title={title[2]}
            desc={desc[2]}
            icon={WechatOutlined}
            index={3}
          />
        </Col>
        <Col
          xl={{ span: 4, order: 5 }}
          lg={{ span: 6, order: 4 }}
          xs={{ order: 4, span: 24 }}
          sm={{ order: 4, span: 12 }}
          md={{ span: 12, order: 4 }}
        >
          <ImageGroup
            title={title[3]}
            desc={desc[3]}
            icon={ShoppingOutlined}
            index={4}
          />
        </Col>
        <Col
          xl={{ span: 2, order: 6, offset: 2 }}
          // lg={{ span: 2, order: 6, offset: 2 }}
          lg={0}
          xs={0}
          sm={0}
          md={0}
        >
          <RightBar
            number={"03"}
            handleClick={handleClick}
            home={home}
            buyer={buyer}
            seller={seller}
            ourworks={ourworks}
          />
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <div style={{ padding: "50px" }}>
          <Button
            size="large"
            style={{
              fontFamily: "Oswald",
              borderRadius: "10px",
              color: "black",
              backgroundColor: "#f7cb0c",
              fontSize: "18px",
            }}
            onClick={() => {
              history.push("/auth/register-2");
            }}
          >
            START TAKING ORDERS
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default BuyerProcess;
