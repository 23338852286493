// import axios from "axios";
// require('dotenv').config()

// console.log('process.env.REACT_APP_AUTHORIZATION_TOKEN==>',process.env.REACT_APP_AUTHORIZATION_TOKEN);

// export default axios.create({
// 	baseURL: process.env.REACT_APP_API_URL,
// 	headers: {
// 		Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
// 		AppVersion: process.env.REACT_APP_APP_VERSION,
// 	},
// });

import axios from "axios";
require("dotenv").config();

console.log("REACT_APP_API_URL-->", process.env.REACT_APP_API_URL);

// export default axios.create({
// 	baseURL: process.env.REACT_APP_API_URL,
// 	headers: {
// 		Authorization: process.env.REACT_APP_AUTHORIZATION_TOKEN,
// 		AppVersion: process.env.REACT_APP_APP_VERSION,
// 	},
// });

export default axios.create({
  baseURL: "https://backend.vendor-park.com/api/",

  // baseURL: "http://localhost:7722/api/",
  headers: {
    Authorization: "WlNLaXJ2c0FFdDpaeFB4R0pINllx",
    AppVersion: "1.0.0",
  },
});
