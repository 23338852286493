import { Button, Col, Divider, Row, Typography } from "antd";

import RightBar from "../components/RightBar";
import SideBar from "../components/SideBar";
import React from "react";
import bars1 from "../images/bars1.png";
import bars2 from "../images/bars2.png";
import screws from "../images/screws.png";

import "../styles/main.css";
import HowItWorksCard from "../components/HowItWorksCard";
import ImageGroup from "../components/ImageGroup";
import {
  CloudUploadOutlined,
  SelectOutlined,
  ToolOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { Title } = Typography;
const BuyerProcess = ({ handleClick, home, buyer, seller, ourworks }) => {
  let history = useHistory();

  const title = [
    "Upload your files",
    "Choose the process",
    "Order your parts online",
    "Receive your parts",
  ];
  const desc = [
    "Multiple designs for different parts can be imported at the same time to produce a single quote. Upload your design files or wait for an NDA from seller to protect your designs.",
    "Choose a manufacturing process you require. You can then choose your category, sub-category and specify requirement scale as one-time, monthly or yearly.",
    "Once your RFQ is up for sellers to review, all you need to do is wait for sellers to send their quotations. You also have the option to chat with sellers!",
    "Within a short amount of time, you will receive the parts you ordered. You can connect to the seller via our live chat feature to track your package at any time.",
  ];
  return (
    <div
      className="buyerprocess"
      ref={buyer}
      id="buyer"
      style={{ marginTop: "120px", marginBottom: "100px" }}
    >
      <Row type="flex" justify="center">
        <div
          style={{ width: "60%", display: "flex", justifyContent: "center" }}
        >
          <h1
            style={{
              color: "white",
              fontWeight: "1000",
              fontSize: "40px",
              fontFamily: "Oswald",
              // fontStretch: "condensed",
            }}
          >
            How to order parts?
          </h1>
        </div>
        <div
          style={{ width: "60%", display: "flex", justifyContent: "center" }}
        >
          <Divider style={{ borderColor: "#f7cb0c" }} />
        </div>
      </Row>
      <Row
        type="flex"
        justify="start"
        align="middle"
        // style={{ height: "100vh" }}
      >
        <Col
          xl={{ span: 4, order: 1 }}
          // lg={{ span: 4, order: 1 }}
          lg={0}
          xs={0}
          sm={0}
          md={0}
        >
          <SideBar />
        </Col>

        <Col
          xl={{ span: 4, order: 3 }}
          lg={{ span: 6, order: 1 }}
          xs={{ order: 2, span: 24 }}
          sm={{ order: 2, span: 12 }}
          md={{ span: 12, order: 1 }}
        >
          <ImageGroup
            title={title[0]}
            desc={desc[0]}
            icon={CloudUploadOutlined}
            index={1}
          />
        </Col>
        <Col
          xl={{ span: 4, order: 3 }}
          lg={{ span: 6, order: 2 }}
          xs={{ order: 2, span: 24 }}
          sm={{ order: 2, span: 12 }}
          md={{ span: 12, order: 2 }}
        >
          <ImageGroup
            title={title[1]}
            desc={desc[1]}
            icon={SelectOutlined}
            index={2}
          />
        </Col>
        <Col
          xl={{ span: 4, order: 4 }}
          lg={{ span: 6, order: 3 }}
          xs={{ order: 3, span: 24 }}
          sm={{ order: 3, span: 12 }}
          md={{ span: 12, order: 3 }}
        >
          <ImageGroup
            title={title[2]}
            desc={desc[2]}
            icon={ToolOutlined}
            index={3}
          />
        </Col>
        <Col
          xl={{ span: 4, order: 5 }}
          lg={{ span: 6, order: 4 }}
          xs={{ order: 4, span: 24 }}
          sm={{ order: 4, span: 12 }}
          md={{ span: 12, order: 4 }}
        >
          <ImageGroup
            title={title[3]}
            desc={desc[3]}
            icon={ShoppingOutlined}
            index={4}
          />
        </Col>
        <Col
          xl={{ span: 2, order: 6, offset: 2 }}
          // lg={{ span: 2, order: 6, offset: 2 }}
          lg={0}
          xs={0}
          sm={0}
          md={0}
        >
          <RightBar
            number={"02"}
            handleClick={handleClick}
            home={home}
            buyer={buyer}
            seller={seller}
            ourworks={ourworks}
          />
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <div style={{ padding: "50px" }}>
          <Button
            size="large"
            style={{
              fontFamily: "Oswald",
              borderRadius: "10px",
              color: "black",
              backgroundColor: "#f7cb0c",
              fontSize: "18px",
            }}
            onClick={() => {
              history.push("/auth/register-2");
            }}
          >
            GET A QUOTE
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default BuyerProcess;
