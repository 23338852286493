import React from "react";
import "../styles/main.css";
import { Button, Col, Row, Space } from "antd";
import MediaButton from "./MediaButton";

const SideBar = () => {
  return (
    <>
      {/* <Row
        wrap={false}
        gutter={2}
        style={{ display: "inline-flex", marginTop: "180px", height: "500px" }}
        justify="space-between"
      >
        <Col>
          <Button className="button-group antd-btn">YouTube</Button>
        </Col>
        <Col>
          <Button className="button-group antd-btn">Instagram</Button>
        </Col>
        <Col>
          <Button className="button-group antd-btn">Facebook</Button>
        </Col>
      </Row> */}
      <div className="lets-align" style={{ fontFamily: "Oswald" }}>
        <Col flex={2}>
          <div className="button-group ">
            <Button
              className="media-btn"
              type="link"
              style={{ color: "#fff", fontSize: "18px" }}
            >
              YOUTUBE
            </Button>

            <Button
              className="media-btn"
              type="link"
              style={{ color: "#fff", fontSize: "18px" }}
            >
              INSTAGRAM
            </Button>

            <Button
              className="media-btn"
              type="link"
              style={{ color: "#fff", fontSize: "18px" }}
            >
              FACEBOOK
            </Button>
          </div>
        </Col>
      </div>
      {/* <Col>
        <div className="button-group">
          <Row style={{ backgroundColor: "red" }} align="top" wrap={false}>
            <Button className="antd-btn">YouTube</Button>

            <Button className="antd-btn">Instagram</Button>

            <Button className="antd-btn">Facebook</Button>
          </Row>
        </div>
      </Col> */}
    </>
  );
};

export default SideBar;
