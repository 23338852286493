import { Image, Typography, Divider, Col, Row } from "antd";
import React from "react";
// import placeholder from "../images/placeholder.png";
const { Title, Text } = Typography;
const ImageCard = () => {
  return (
    <>
      <div>
        <Row
          justify="space-between"
          gutter={2}
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Col lg={{ span: 6, order: 1 }}>
            {/* <Image width={40} height={40} src={placeholder}></Image> */}
            <div className="card-small">
              {/* <img src={placeholder} alt="Alternate"></img> */}
            </div>
          </Col>
          <Col lg={{ span: 18, order: 2 }}>
            <Text
              underline
              style={{
                color: "#f7cb0c",
                display: "flex",
                justifyContent: "center",
              }}
            >
              PRODUCT NAME
            </Text>
            {/* <Divider style={{ borderColor: "#f7cb0c" }} /> */}
            <h6 style={{ padding: "5px", color: "white", textAlign: "center" }}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempora,
              quis
            </h6>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ImageCard;
