import { Col, Row, Typography } from "antd";
import React from "react";
import image from "../images/newgroup.png";
import { CloudUploadOutlined } from "@ant-design/icons";
const { Title } = Typography;
const ImageGroup = (props) => {
  return (
    // <div style={{ display: "flex", justifyContent: "center" }}>
    //   <img
    //     src={image}
    //     style={{
    //       width: "12rem",
    //       height: "20rem",
    //     }}
    //   ></img>
    // </div>
    <div
      style={{
        fontFamily: "Oswald",
        padding: "20px",
        // display: "block",
      }}
    >
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "10rem",
                height: "10rem",
              }}
            >
              <props.icon style={{ fontSize: "100px" }} />
            </div>
          </Row>
          <Row>
            <br />
            <Title level={2} style={{ marginBotton: "40px", color: "white" }}>
              {props.title}
            </Title>
          </Row>
        </Col>
        <Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: "10rem",
              // height: "10rem",
            }}
          >
            <div>
              <h1
                style={{
                  color: "white",
                  fontWeight: "1000",
                  fontSize: "60px",
                  padding: "10px",
                  // fontStretch: "condensed",
                }}
              >
                {props.index}
              </h1>
            </div>
            <div>
              <p
                style={{
                  color: "grey",
                  fontWeight: "200",
                  fontSize: "15px",
                  // fontStretch: "condensed",
                }}
              >
                {props.desc}
              </p>
            </div>
          </div>
        </Row>
      </Row>
    </div>
  );
};

export default ImageGroup;
