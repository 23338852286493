import { combineReducers } from "redux";
import Auth from "./Auth";
import category from "./Categories";
import Theme from "./Theme";
import user from "./User";
// import chatReducer from "./Chat";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  user: user,
  category: category,
  //   chatReducer: chatReducer,
});

export default reducers;
