import { FETCHCATEGORY, FETCHSUBCATEGORY, ADDSUBCATEGORY} from "redux/constants/Categories";

const initialState = {
  categoryList: [],
  subCategoryList: [],
  subCategoryDetails:{}
};

const category = (state = initialState, action) => {
  switch (action.type) {
    case FETCHCATEGORY:
      return {
        ...state,
        categoryList: action.payload,
      };
    case FETCHSUBCATEGORY:
      return {
        ...state,
        subCategoryList: action.payload,
      };
    case ADDSUBCATEGORY:
      return{
        ...state,
        subCategoryDetails: action.payload,
      }
    default:
      return state;
  }
};

export default category;
