import React from "react";
import { APP_NAME } from "configs/AppConfig";
import { Col, Divider, Row } from "antd";

export default function Footer() {
  return (
    <>
      {/* <footer className="footer">
        <span>
          Copyright &copy; {`${new Date().getFullYear()}`}{" "}
          <span className="font-weight-semibold">{`${APP_NAME}`}</span> All
          rights reserved.
        </span>
        <div>
          <a
            className="text-gray"
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            Term & Conditions
          </a>
          <span className="mx-2 text-muted"> | </span>
          <a
            className="text-gray"
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            Privacy & Policy
          </a>
        </div>
      </footer> */}

      <Divider />

      <div
        style={{ fontSize: "20px", fontFamily: "sans-serif", padding: "15px" }}
      >
        <Row>
          <Col span={12}>
            Copyright &copy; {`${new Date().getFullYear()}`}{" "}
            <span className="font-weight-semibold">{`${APP_NAME}`}</span> All
            rights reserved.
          </Col>
          <Col span={12}>
            {" "}
            <a
              className="text-gray"
              href="/#"
              onClick={(e) => e.preventDefault()}
            >
              Terms & Conditions
            </a>
            <span className="mx-2 text-muted"> | </span>
            <a
              className="text-gray"
              href="/#"
              onClick={(e) => e.preventDefault()}
            >
              Privacy & Policy
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
}
