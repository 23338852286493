import React from "react";

const Pic = ({ img }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <img src={img} style={{ width: "90%", height: "90%" }}></img>
    </div>
  );
};

export default Pic;
