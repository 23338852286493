import React from "react";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import { APP_NAME } from "configs/AppConfig";
import { connect } from "react-redux";
import utils from "utils";
import { Button, Grid } from "antd";
import logo from "../../landing-page/images/logo-light.png";
const { useBreakpoint } = Grid;
// import { useHistory } from "react-router-dom";
// let history = useHistory();

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  if (logoType === "light") {
    if (navCollapsed) {
      // return "/img/vendor-park-logo-sm.png";
      return "/img/vp-logo.png";
    }
    // return "/img/vp-logo.png";
    return "/img/vp-logo.png";
  }

  if (navCollapsed) {
    return "/img/vp-logo.png";
    // return "/img/vendor-park-logo-sm.png";
  }
  // return "/img/vp-logo.png";
  return "/img/vp-logo.png";
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo";
  }
};

// import { useHistory } from "react-router-dom";

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  // let history = useHistory();
  const redirect = () => {
    // history.push("/app/dashboards/default");
  };

  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <div
        style={{
          //   marginRight: "200px",
          //   padding: "30px",
          //   marginLeft: "-60px",
          width: "100%",
          //  props.navCollapsed ? "170px" : "200px",
          height: "100%",
          //  props.navCollapsed ? "170px" : "200px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <a
          href="/auth/home"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <img
            // onClick={redirect}
            // href="/auth/home"
            src={
              // logo
              getLogo(props)
            }
            alt={`${APP_NAME} logo`}
            style={{
              // marginRight: "200px",
              padding: "0",
              marginLeft: "0",
              marginTop: "0",
              width: props.navCollapsed ? "100px" : "100px",
              height: props.navCollapsed ? "80px" : "80px",
              // width: "80%",

              // height: "100px",
            }}
          ></img>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
