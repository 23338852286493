import { Col, Row } from "antd";
import React from "react";
import Header from "../components/Header";
import RightBar from "../components/RightBar";
import SideBar from "../components/SideBar";
import Features from "./Features";
import Feedback from "./Feedback";
import Home from "./Home";
import BuyerProcess from "./BuyerProcess";
import SellerProcess from "./SellerProcess";
import Categories from "./Categories";
import Footer from "./Footer";
import { useRef, useState } from "react";
import OurWork from "./OurWork";
import { logDOM } from "@testing-library/react";
import { useEffect } from "react";
const Layout = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const home = useRef(null);
  const buyer = useRef(null);
  const seller = useRef(null);
  const ourworks = useRef(null);
  const [active, setActive] = useState({
    home: true,
    buyer: false,
    seller: false,
    ourworks: false,
  });
  // const feedback = useRef(null);

  const handleClick = (location) => {
    setActive({
      home: home === location ? true : false,
      buyer: buyer === location ? true : false,
      seller: seller === location ? true : false,
      ourworks: ourworks === location ? true : false,
    });
    let id = "home";
    home === location
      ? (id = "home")
      : buyer === location
      ? (id = "buyer")
      : seller === location
      ? (id = "seller")
      : (id = "ourworks");
    // var node = document.getElementById(id);

    const yOffset = -110;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    console.log("nah", window.pageYOffset);
    if (location == home) {
      window.scroll(0, 0);
    } else {
      // location.current?.scrollIntoView
      window.scrollTo({
        top: y,
        behavior: "smooth",
        // block: "start",
        // inline: "center",
      });
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    // console.log("lll",document.body.getBoundingClientRect().top);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div style={{ backgroundColor: "#12111f" }}>
      <Row>
        <Header
          handleClick={handleClick}
          home={home}
          buyer={buyer}
          seller={seller}
          ourworks={ourworks}
          active={active}
          setActive={setActive}
        />
      </Row>

      <Home
        ref={home}
        handleClick={handleClick}
        home={home}
        buyer={buyer}
        seller={seller}
        ourworks={ourworks}
      />
      <BuyerProcess
        ref={buyer}
        handleClick={handleClick}
        home={home}
        buyer={buyer}
        seller={seller}
        ourworks={ourworks}
      />
      <SellerProcess
        ref={seller}
        handleClick={handleClick}
        home={home}
        buyer={buyer}
        seller={seller}
        ourworks={ourworks}
      />
      <OurWork
        ref={ourworks}
        handleClick={handleClick}
        home={home}
        buyer={buyer}
        seller={seller}
        ourworks={ourworks}
      />
      {/* <Footer></Footer> */}
    </div>
  );
};

export default Layout;
