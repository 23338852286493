import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import Layout from "landing-page/pages/Layout";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "redux/store";

const themes = {
	dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
	light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
	return (
		<div className="App">
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ThemeSwitcherProvider
						themeMap={themes}
						defaultTheme={THEME_CONFIG.currentTheme}
						insertionPoint="styles-insertion-point"
					>
						<Router>
							<Switch>
								<Route path="/" component={Views} />
							</Switch>
						</Router>
					</ThemeSwitcherProvider>
				</PersistGate>
			</Provider>
		</div>
	);
}

export default App;
