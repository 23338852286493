import {
  BookOutlined,
  HomeOutlined,
  DollarOutlined,
  SettingOutlined,
  LogoutOutlined,
  DashboardOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { SELLER_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import sellerViews from "views/seller-views";

const sellerScreenTree = [
  // {
  //   key: "items",
  //   path: `${SELLER_PREFIX_PATH}/items`,
  //   title: "Items",
  //   icon: BookOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "additems",
  //       path: `${SELLER_PREFIX_PATH}/items/additems`,
  //       title: "Add Items",
  //       icon: BookOutlined,
  //       breadcrumb: false,
  //       submenu: [],
  //     },
  //     {
  //       key: "itemlist",
  //       path: `${SELLER_PREFIX_PATH}/items/itemlist`,
  //       title: "Item List",
  //       icon: BookOutlined,
  //       breadcrumb: false,
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    key: "rfq",
    path: `${SELLER_PREFIX_PATH}/rfq`,
    title: "RFQ",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "rfq",
        path: `${SELLER_PREFIX_PATH}/rfq/listingRFQ`,
        title: "RFQ List",
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "rfq",
      //   path: `${SELLER_PREFIX_PATH}/rfq/filterRFQ`,
      //   title: "Filter RFQ",
      //   icon: BookOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];
const sellerHomeNavTree = [
  {
    key: "home",
    path: `${SELLER_PREFIX_PATH}/home`,
    title: "HOME",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "home-dashboard",
        path: `${SELLER_PREFIX_PATH}/home`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "home-profile",
        path: `${SELLER_PREFIX_PATH}/profile`,
        title: "Profile",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  // {
  //   key: "payment",
  //   path: `${SELLER_PREFIX_PATH}/docs`,
  //   title: "SUBSCRIPTION",
  //   icon: DollarOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "payment-subscription",
  //       path: `${SELLER_PREFIX_PATH}/docs/documentation`,
  //       title: "Payment",
  //       icon: DollarOutlined,
  //       breadcrumb: false,
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   key: "settings",
  //   path: `${AUTH_PREFIX_PATH}/login`,
  //   title: "SETTINGS",
  //   icon: SettingOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "settings-logout",
  //       path: `${AUTH_PREFIX_PATH}/login`,
  //       title: "Sign Out",
  //       icon: LogoutOutlined,
  //       breadcrumb: false,
  //       submenu: [],
  //     },
  //   ],
  // },
];
// const sellerNavConfig = [...sellerScreenTree];
const sellerNavConfig = [...sellerHomeNavTree];
export default sellerNavConfig;
