import {
  FETCHUSER,
  ADDUSER,
  FETCHALLUSER,
  FETCHBLACKLISTEDUSER,
  FIREBASEUSER,
  STOREFIREBASEUSER,
  SAVEFIREBASEUSERID,
  VALIDATEUSER,
} from "redux/constants/User";

import { store } from "redux/store";

import API from "services/API/API";

export const fetchuser = (data, callback) => (dispatch) => {
  console.log("inside fetchuser=?data=?", data);
  const token = store.getState().auth.userInfo.AuthToken;

  API.get(`user/fetchuser?UserRef=${data}`, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("res", res);
    if (res.data?.Details?.Users) {
      console.log("Users", res.data?.Details?.Users);
      dispatch({ type: FETCHUSER, payload: res.data?.Details?.Users[0] });
      callback({ exists: false, airports: res.data?.Details?.Users[0] });
    }
  });
};

export const adduser = (data, callback) => (dispatch) => {
  console.log("export===>addUser-->", data);

  const token = store.getState().auth.userInfo.AuthToken;

  console.log("token", token);

  API.post(`user/adduser`, data, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("adduser response--->", res);
    console.log("res.data.Details.Users", res.data.Details);
    if (res.data.Details != null) {
      dispatch({ type: ADDUSER, payload: res.data.Details });
      callback({
        exists: false,
        airports: res.data.Details,
        // error:res.data?.Error.ErrorMessage
      });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const fetchAllUser = (data, callback) => (dispatch) => {
  const token = store.getState().auth.userInfo.AuthToken;
  console.log("token", token);
  API.get(`user/fetchuser?UserRef=${data}`, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("fetchAllUser-->res", res);
    console.log("fetchAllUser-->res.data.Details", res.data.Details);
    if (res.data.Details) {
      dispatch({ type: FETCHALLUSER, payload: res.data.Details.Users });
      callback({ exists: false, UsersList: res.data.Details.Users });
    }
  });
};

export const blackListUsers = (data, callback) => (dispatch) => {
  console.log("blacklistUsers");
  const token = store.getState().auth.userInfo.AuthToken;
  API.post(`user/blacklistuser`, data, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("blacklistuser response--->", res);
    console.log("res.data.Details.Users", res.data.Details);
    if (res.data.Details != null) {
      // dispatch({ type: ADDUSER, payload: res.data.Details });
      callback({
        exists: false,
        Blacklist: res.data.Details,
        // error:res.data?.Error.ErrorMessage
      });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const fetchBlacklistedUsers = (callback) => (dispatch) => {
  console.log("check fetchBlacklistedUsers data");
  const token = store.getState().auth.userInfo.AuthToken;

  API.get(`user/blacklistedusers`, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("fetchBlacklistedUsers-->res", res);
    console.log(
      "fetchAllUser-->res.data.Details-->fetchBlacklistedUsers",
      res.data.Details
    );
    if (res.data.Details != null) {
      dispatch({ type: FETCHBLACKLISTEDUSER, payload: res.data.Details });
      callback({ exists: false, BlacklistedUsers: res.data.Details });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const removeBlacklistedUsers = (data, callback) => (dispatch) => {
  console.log("check blackListUsers data", data);
  const token = store.getState().auth.userInfo.AuthToken;

  API.post(`user/removeblacklisteduser`, data, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("removeBlacklistedUsers-->res", res);
    console.log(
      "fetchBlacklistedUsers-->res.data.Details-->removeBlacklistedUsers",
      res.data.Details
    );
    console.log(
      "fetchBlacklistedUsers-->res.data.Details.Users",
      res.data.Details
    );
    if (res.data.Details != null) {
      // dispatch({ type: REMOVEBLACKLISTUSER, payload: res.data.Details.Users});
      callback({ exists: false, removeBlacklistedUsers: res.data.Details });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const viewUserList = (data, callback) => (dispatch) => {
  console.log("check viewUserList data", data);
  const token = store.getState().auth.userInfo.AuthToken;
  console.log("token--->", token);
  API.get(`user/fetchuser?UserRef=${data}`, {
    headers: { AuthToken: token },
  }).then((res) => {
    // console.log('data', data);
    console.log("viewUserList-->res", res);
    // console.log('fetchAllUser-->res.data.Details',res.data.Details);
    if (res.data.Details) {
      // dispatch({ type: FETCHALLUSER, payload: res.data.Details.Users});
      callback({ exists: false, User: res.data?.Details?.Users });
    }
  });
};

export const fetchBuyersAndSellers = (data, callback) => (dispatch) => {
  console.log("fetchBuyersAndSellers--> data", data);
  const token = store.getState().auth.userInfo.AuthToken;

  API.get(`seller/fetchbuyersandsellers?UserType=${data}`, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("fetchBuyersAndSellers-->res", res);
    // console.log('fetchAllUser-->res.data.Details-->blackListUsers',res.data.Details);
    if (res.data.Details != null) {
      //   // dispatch({ type: FETCHALLUSER, payload: res.data.Details.Users});
      callback({ exists: false, BuyerSellerList: res.data?.Details });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const viewSellerBid = (callback) => (dispatch) => {
  console.log("View seller bid data");
  const token = store.getState().auth.userInfo.AuthToken;

  API.get(`seller/viewsellerbid`, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("-->res", res);

    if (res.data.Details != null) {
      //   // dispatch({ type: FETCHALLUSER, payload: res.data.Details.Users});
      callback({ exists: false, viewbidseller: res.data?.Details });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const ExtendTrial = (data, callback) => (dispatch) => {
  console.log("View seller bid data");
  const token = store.getState().auth.userInfo.AuthToken;
  console.log("okay ceck ths", data); // DATA 1
  API.post(`seller/extendtrialperiod`, data, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("-->res in ext trail", res); // RESPONSE

    if (res.data.Details != null) {
      callback({ exists: false, extended: res.data.Details });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const FirebaseUser = (data) => (dispatch) => {
  console.log("StoreMarriedSegmentsSet-->data", data);
  console.log("checkijjjj");
  dispatch({
    type: FIREBASEUSER,
    payload: data,
  });
};

export const StoreFirebaseUser = (data, callback) => (dispatch) => {
  console.log("StoreFirebaseUser==>data==>", data);
  dispatch({
    type: STOREFIREBASEUSER,
    payload: data,
  });
};

export const saveFirebaseUserId = (data, callback) => (dispatch) => {
  console.log("saveFirebaseUserId=>data->", data);
  callback({
    exists: false,
    firebaseUserId: data,
  });
  dispatch({
    type: SAVEFIREBASEUSERID,
    payload: data,
  });
};

export const validateUser = (data, callback) => (dispatch) => {
  console.log("export===>validateUser-->", data);

  // const token = store.getState().auth.userInfo.AuthToken;

  // console.log("token", token);

  API.post(`user/validateuser`, data, {
    // headers: { AuthToken: token },
  }).then((res) => {
    console.log("validateuser response--->", res);
    console.log("validateuser->res.data.Details.Users", res.data.Details);
    if (res.data.Details != null) {
      dispatch({ type: VALIDATEUSER, payload: res.data.Details });
      callback({
        exists: false,
        validateUserresponse: res.data.Details,
        // error:res.data?.Error.ErrorMessage
      });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const updateUserPassword = (data, callback) => (dispatch) => {
  console.log("export===>updateUserPassword-->", data);

  // const token = store.getState().auth.userInfo.AuthToken;

  // console.log("token", token);

  API.post(`user/forgotpassword`, data, {
    // headers: { AuthToken: token },
  }).then((res) => {
    console.log("forgotpassword response--->", res);
    console.log("forgotpassword->res.data.Details.Users", res.data.Details);
    if (res.data.Details != null) {
      // dispatch({ type: VALIDATEUSER, payload: res.data.Details });
      callback({
        exists: false,
        updatePasswordResponse: res.data.Details,
        // error:res.data?.Error.ErrorMessage
      });
    } else if (res.data.Error) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};
