import React from "react";
import { Divider, Typography } from "antd";
// import title from "landing-page/styles/title";

const { Title, Paragraph } = Typography;
const HowItWorksCard = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontFamily: "Oswald",
        }}
      >
        <div
          className="overlay-img-2"
          style={{
            marginTop: "55px",
          }}
        >
          <div className="card-small"></div>
        </div>
        <div
          className="card"
          style={{
            padding: "10px",
            marginTop: "50px",
            fontFamily: "Montserrat ( Extrabold)",
          }}
        >
          <div style={{ marginTop: "100px" }}>
            <Title level={4} style={{ color: "#f7cb0c" }}>
              PRODUCT NAME
            </Title>

            <h6 style={{ color: "white" }}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempora,
              quis et sint corrupti cupiditate quidem, assumenda eveniet nobis,
              recusandae rerum laudantium?
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksCard;
