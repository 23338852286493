import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
	APP_PREFIX_PATH,
	AUTH_PREFIX_PATH,
	SELLER_PREFIX_PATH,
	BUYER_PREFIX_PATH,
	HOME_PREFIX_PATH,
} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import SellerLayout from "layouts/seller-layout";
import BuyerLayout from "layouts/buyer-layout";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated ? (
					(console.log("authenticated"), children)
				) : (
					<Redirect
						to={{
							pathname: AUTH_PREFIX_PATH,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

export const Views = (props) => {
	console.log("check props in Views", props);
	const { locale, token, location, direction, userInfo } = props;
	const currentAppLocale = AppLocale[locale];
	useBodyClass(`dir-${direction}`);
	return (
		console.log("token", token),
		console.log("userInfo", userInfo),
		(
			<IntlProvider
				locale={currentAppLocale.locale}
				messages={currentAppLocale.messages}
			>
				<ConfigProvider locale={currentAppLocale.antd} direction={direction}>
					<Switch>
						<Route exact path="/">
							<Redirect to={HOME_PREFIX_PATH} />
						</Route>
						<Route path={AUTH_PREFIX_PATH}>
							<AuthLayout direction={direction} />
						</Route>
						<Route exact path={SELLER_PREFIX_PATH}>
							<SellerLayout direction={direction} />
						</Route>
						<Route exact path={BUYER_PREFIX_PATH}>
							<BuyerLayout direction={direction} />
						</Route>
						<RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
							<AppLayout direction={direction} location={location} />
						</RouteInterceptor>
						<RouteInterceptor path={SELLER_PREFIX_PATH} isAuthenticated={token}>
							<SellerLayout location={location} />
						</RouteInterceptor>
						<RouteInterceptor path={BUYER_PREFIX_PATH} isAuthenticated={token}>
							<BuyerLayout location={location} />
						</RouteInterceptor>
					</Switch>
				</ConfigProvider>
			</IntlProvider>
		)
	);
};

const mapStateToProps = ({ theme, auth }) => {
	const { locale, direction } = theme;
	const { token, userInfo } = auth;
	return { locale, direction, token, userInfo };
};

export default withRouter(connect(mapStateToProps)(Views));
