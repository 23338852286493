import { Button, Typography } from "antd";

import React, { useState } from "react";
const { Title } = Typography;
const PageButton = ({ number }) => {
  const stylenew = {
    border: "none",
    color: "#fff",
    marginRight: "20px",
    padding: "15px",
  };
  const [color, setColor] = useState("white");
  const location =
    number === "01"
      ? "#home"
      : number === "02"
      ? "#categories"
      : number === "03"
      ? "#features"
      : number === "04"
      ? "#howitworks"
      : "#feedback";
  return (
    <div>
      <a href={location}>
        <button
          // className="page-button"
          style=
          {{
          borderColor: "#12111f",
          border: "none",
          marginRight: "20px",
          padding: "15px",
          backgroundColor:'white'
          }}
          // size="large"
          // type="link"
        >
          <Title
            level={2}
            style={{ color: color, fontWeight: "500" }}
            // onClick={() => setColor("#f7cb0c")}
          >
            {number}
          </Title>
        </button>
      </a>
    </div>
  );
};

export default PageButton;
