import { Button, Col, Divider, Row, Typography } from "antd";

import RightBar from "../components/RightBar";
import SideBar from "../components/SideBar";
import React from "react";
import bars1 from "../images/bars1.png";
import bars2 from "../images/bars2.png";
import screws from "../images/screws.png";

import "../styles/main.css";
import HowItWorksCard from "../components/HowItWorksCard";
import ImageGroup from "../components/ImageGroup";
import {
  CloudUploadOutlined,
  SelectOutlined,
  ToolOutlined,
  ShoppingOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Pic from "../components/Pic";
import pic1 from "../images/pic1.png";
import pic2 from "../images/pic2.png";
import pic3 from "../images/pic3.png";
import pic4 from "../images/pic4.png";
import pic5 from "../images/SHAFTS.png";
import pic6 from "../images/specialscrews.png";
import pic7 from "../images/steel.png";
import pic8 from "../images/WASHER.png";
import pic9 from "../images/pins.png";
import pic10 from "../images/brass.png";
import { useState } from "react";

const { Title } = Typography;
const OurWork = ({ handleClick, home, buyer, seller, ourworks }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      ref={ourworks}
      id="ourworks"
      style={{
        fontFamily: "Oswald",
        marginTop: "120px",
        marginBottom: "60px",
      }}
    >
      <Row type="flex" justify="center">
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            fontFamily: "Oswald",
          }}
        >
          <h1
            style={{
              color: "grey ",
              fontWeight: "1000",
              fontSize: "40px",
              fontFamily: "Oswald",

              // fontStretch: "condensed",
            }}
          >
            Follow @VendorPark to see the beauty of Metal Machining
          </h1>
        </div>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Divider style={{ borderColor: "#f7cb0c" }} />
        </div>
      </Row>
      <Row type="flex" justify="start" align="middle">
        <Col
          xl={{ span: 4, order: 1 }}
          //   lg={{ span: 4, order: 1 }}
          lg={0}
          xs={0}
          sm={0}
          md={0}
        >
          <SideBar />
        </Col>
        <Col xl={{ span: 18, order: 2 }}>
          <Row justify="center">
            <Col
              xl={{ span: 6, order: 3 }}
              lg={{ span: 6, order: 1 }}
              xs={{ order: 2, span: 24 }}
              sm={{ order: 2, span: 12 }}
              md={{ span: 12, order: 1 }}
            >
              <Pic img={pic1} />
            </Col>
            <Col
              xl={{ span: 6, order: 3 }}
              lg={{ span: 6, order: 2 }}
              xs={{ order: 2, span: 24 }}
              sm={{ order: 2, span: 12 }}
              md={{ span: 12, order: 2 }}
            >
              <Pic img={pic9} />
            </Col>
            <Col
              xl={{ span: 6, order: 4 }}
              lg={{ span: 6, order: 3 }}
              xs={{ order: 3, span: 24 }} 
              sm={{ order: 3, span: 12 }}
              md={{ span: 12, order: 3 }}
            >
              <Pic img={pic10} />
            </Col>
            <Col
              xl={{ span: 6, order: 5 }}
              lg={{ span: 6, order: 4 }}
              xs={{ order: 4, span: 24 }}
              sm={{ order: 4, span: 12 }}
              md={{ span: 12, order: 4 }}
            >
              <Pic img={pic4} />
            </Col>
          </Row>
          {/* {!showMore && ( */}

          {/* )} */}
          {showMore && (
            <Row justify="center">
              <Col
                xl={{ span: 6, order: 3 }}
                lg={{ span: 6, order: 1 }}
                xs={{ order: 2, span: 24 }}
                sm={{ order: 2, span: 12 }}
                md={{ span: 12, order: 1 }}
              >
                <Pic img={pic5} />
              </Col>
              <Col
                xl={{ span: 6, order: 3 }}
                lg={{ span: 6, order: 2 }}
                xs={{ order: 2, span: 24 }}
                sm={{ order: 2, span: 12 }}
                md={{ span: 12, order: 2 }}
              >
                <Pic img={pic6} />
              </Col>
              <Col
                xl={{ span: 6, order: 4 }}
                lg={{ span: 6, order: 3 }}
                xs={{ order: 3, span: 24 }}
                sm={{ order: 3, span: 12 }}
                md={{ span: 12, order: 3 }}
              >
                <Pic img={pic7} />
              </Col>
              <Col
                xl={{ span: 6, order: 5 }}
                lg={{ span: 6, order: 4 }}
                xs={{ order: 4, span: 24 }}
                sm={{ order: 4, span: 12 }}
                md={{ span: 12, order: 4 }}
              >
                <Pic img={pic8} />
              </Col>
            </Row>
          )}
          <Row
            justify="center"
            style={{
              padding: "20px",
            }}
          >
            <Button
              icon={showMore ? <UpOutlined /> : <DownOutlined />}
              onClick={() => {
                setShowMore(!showMore);
              }}
              style={{
                backgroundColor: "#f7cb0c",
                color: "black",
              }}
            >
              SHOW {showMore ? "LESS" : "MORE"} IMAGES
            </Button>
          </Row>
        </Col>
        <Col
          xl={{ span: 2, order: 6 }}
          //   lg={{ span: 2, order: 6, offset: 2 }}
          lg={0}
          xs={0}
          sm={0}
          md={0}
        >
          <RightBar
            number={"04"}
            handleClick={handleClick}
            home={home}
            buyer={buyer}
            seller={seller}
            ourworks={ourworks}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OurWork;
