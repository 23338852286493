import { Col, Row } from "antd";
import Description from "../components/Description";
import Feed from "../components/Feed";
import Header from "../components/Header";
import MediaButton from "../components/MediaButton";
import PageNumber from "../components/PageNumber";
import RightBar from "../components/RightBar";
import SideBar from "../components/SideBar";
import React from "react";
import bars3 from "../images/bars3.png";
import main from "../images/main.png";
import i1 from "../images/i11.PNG";
import i2 from "../images/i22.PNG";
import i3 from "../images/i33.PNG";
import i4 from "../images/i44.PNG";
import i5 from "../images/i55.PNG";
import i6 from "../images/i66.PNG";
import vphome from "../images/vphome.png";
import "../styles/main.css";
const Home = ({ handleClick, home, buyer, seller, ourworks }) => {
  return (
    <>
      <div
        // className="home"
        ref={home}
        id="home"
        style={{
          backgroundColor: "#12111F",
          position: "relative",

          // backgroundImage: { vphome },
        }}
      >
        <Row
          // gutter={2}
          type="flex"
          justify="space-around"
          align="middle"
          style={{ marginTop: "120px", marginBottom: "80px" }}
        >
          <Col
            xl={{ span: 4, order: 1 }}
            // lg={{ span: 4, order: 1 }}
            lg={0}
            xs={0}
            sm={0}
            md={0}
          >
            <SideBar />
          </Col>
          <Col
            xl={{ span: 6, order: 2 }}
            lg={{ span: 10, order: 2 }}
            xs={{ order: 1 }}
            sm={{ order: 1 }}
            md={{ span: 10 }}
          >
            <Feed />
          </Col>
          <Col
            xl={{ span: 12, order: 3 }}
            lg={{ span: 14, order: 3 }}
            xs={{ span: 0, order: 1 }}
            sm={{ span: 0, order: 1 }}
            md={{ span: 14 }}
          >
            <div
              className="product-img"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={main} alt="LOGO" style={{ width: "100%" }} />
            </div>
          </Col>
          <Col
            xl={{ span: 0, order: 3 }}
            lg={{ span: 0, order: 3 }}
            xs={{ order: 2 }}
            sm={{ order: 2 }}
            md={{ span: 0 }}
          >
            <div
              className="product-img"
              // style={{ display: "flex", justifyContent: "center" }}
            >
              <Col>
                <Row align="middle" justify="center">
                  <Col span={12}>
                    <img src={i1} alt="LOGO" />
                  </Col>
                  <Col span={12}>
                    <img src={i4} alt="LOGO" />
                  </Col>
                </Row>
                <Row align="middle" justify="center">
                  <Col span={12}>
                    <h6 style={{ display: "flex", justifyContent: "center" }}>
                      DIE CASTING
                    </h6>
                  </Col>
                  <Col span={12}>
                    <h6 style={{ display: "flex", justifyContent: "center" }}>
                      CNC MACHINE
                    </h6>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row align="middle" justify="center">
                  <Col span={12}>
                    <img src={i2} alt="LOGO" />
                  </Col>
                  <Col span={12}>
                    <img src={i5} alt="LOGO" />
                  </Col>
                </Row>
                <Row align="middle" justify="center">
                  <Col span={12}>
                    <h6 style={{ display: "flex", justifyContent: "center" }}>
                      SHEET METAL
                    </h6>
                  </Col>
                  <Col span={12}>
                    <h6 style={{ display: "flex", justifyContent: "center" }}>
                      SURFACE TREATMENT
                    </h6>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row align="middle" justify="center">
                  <Col span={12}>
                    <img src={i3} alt="LOGO" />
                  </Col>
                  <Col span={12}>
                    <img src={i6} alt="LOGO" />
                  </Col>
                </Row>
                <Row align="middle" justify="center">
                  <Col span={12}>
                    <h6 style={{ display: "flex", justifyContent: "center" }}>
                      3D PRINTING
                    </h6>
                  </Col>
                  <Col span={12}>
                    <h6 style={{ display: "flex", justifyContent: "center" }}>
                      ASSEMBLIES
                    </h6>
                  </Col>
                </Row>
              </Col>
              {/* <Col>
                <Row>
                  <Col span={12}>
                    <img src={i2} alt="LOGO" />
                    <h6>DIE CASTING</h6>
                  </Col>
                  <Col span={12}>
                    <img src={i5} alt="LOGO" />
                    <h6>DIE CASTING</h6>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <img src={i3} alt="LOGO" />
                    <h6>DIE CASTING</h6>
                  </Col>
                  <Col span={12}>
                    <img src={i6} alt="LOGO" />
                    <h6>DIE CASTING</h6>
                  </Col>
                </Row>
              </Col> */}

              {/* <img src={main} alt="LOGO" style={{ width: "100%" }} /> */}
            </div>
          </Col>
          <Col
            // xxl={1}
            xl={{ span: 2, order: 4 }}
            // lg={{ span: 2, order: 4, offset: 2 }}
            lg={0}
            xs={0}
            sm={0}
            md={0}
          >
            <RightBar
              number={"01"}
              handleClick={handleClick}
              home={home}
              buyer={buyer}
              seller={seller}
              ourworks={ourworks}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Home;
