import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

//this firebase file is for chat functions


const firebaseConfig = {
  apiKey: "AIzaSyCBfjnRupIpGMX3HeIUdZyogRhOvG5v9RE",
  authDomain: "vendorpark-6add7.firebaseapp.com",
  projectId: "vendorpark-6add7",
  storageBucket: "vendorpark-6add7.appspot.com",
  messagingSenderId: "557638745893",
  appId: "1:557638745893:web:f4e843807e9356811464cc",
  measurementId: "G-6THHDWGFYD",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
