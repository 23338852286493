import {
  FETCHUSER,
  ADDUSER,
  FETCHALLUSER,
  FETCHBLACKLISTEDUSER,
  FIREBASEUSER,
  STOREFIREBASEUSER,
  CHATWITHSELLER,
  CHATWITHBUYER,
  SAVEFIREBASEUSERID,
  NDAUPLOADFLAG,
  REFERENCEIMAGESFLAG,
  VALIDATEUSER,
} from "redux/constants/User";

const initialState = {
  userDetails: {},
  addUser: {},
  selectedUserId: null,
  usersList: [],
  blacklistedUserList: [],
  firebaseUser: null,
  storeFirebaseUser: {},
  sellerUserName: null,
  buyerUserName: null,
  savedFirebaseUserId: null,
  ndaUploadFlag: null,
  ReferenceImagesUploaded: {},
  ValidateUserResponse: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case FETCHUSER:
      return {
        ...state,
        userDetails: action.payload,
      };

    case ADDUSER:
      return {
        ...state,
        addUser: action.payload,
      };

    case FETCHALLUSER:
      return {
        ...state,
        usersList: action.payload,
      };

    case FETCHBLACKLISTEDUSER:
      return {
        ...state,

        blacklistedUserList: action.payload,
      };
    case FIREBASEUSER:
      return {
        ...state,

        firebaseUser: action.payload,
      };

    case STOREFIREBASEUSER:
      return {
        ...state,

        storeFirebaseUser: action.payload,
      };

    case CHATWITHSELLER:
      return {
        ...state,
        sellerUserName: action.payload,
      };
    case CHATWITHBUYER:
      return {
        ...state,
        buyerUserName: action.payload,
      };
    case SAVEFIREBASEUSERID:
      console.log("SAVEFIREBASEUSERID=>action.payload=>", action.payload);
      return {
        ...state,
        savedFirebaseUserId: action.payload,
      };
    case NDAUPLOADFLAG:
      return {
        ...state,
        ndaUploadFlag: action.payload,
      };
    case REFERENCEIMAGESFLAG:
      return {
        ...state,
        ReferenceImagesUploaded: action.payload,
      };
    case VALIDATEUSER:
      return {
        ...state,
        ValidateUserResponse: action.payload,
      };
    default:
      return state;
  }
};

export default user;
