import { Divider, Typography } from "antd";
import React from "react";

const { Title } = Typography;
const PageNumber = () => {
  return (
    <div style={{ width: "50px" }}>
      <Title
        level={1}
        style={{
          color: "white",
          fontWeight: "1000",
          // fontStretch: "condensed",
        }}
      >
        01
      </Title>
      <Divider style={{ borderColor: "#f7cb0c" }} />
    </div>
  );
};

export default PageNumber;
