import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { SELLER_PREFIX_PATH } from "configs/AppConfig";
// import { AuthContextProvider } from "../../context/AuthContext";
import { ChatContextProvider } from "../../context/ChatContext";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "redux/store";
import { Provider } from "react-redux";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "../../../src/configs/AppConfig";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

export const SellerViews = () => {
  return (
    // <Provider store={store}>
    // // <PersistGate loading={null} persistor={persistor}>
    //   // <ThemeSwitcherProvider
    //   //   themeMap={themes}
    //   //   defaultTheme={THEME_CONFIG.currentTheme}
    //   //   insertionPoint="styles-insertion-point"
    //   >
    // <AuthContextProvider>
    // <ChatContextProvider>
    // <React.StrictMode>
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${SELLER_PREFIX_PATH}/home`}
          component={lazy(() => import("./SellerPage"))}
        />
        <Route
          path={`${SELLER_PREFIX_PATH}/items`}
          component={lazy(() => import("./Items"))}
        />
        <Route
          path={`${SELLER_PREFIX_PATH}/rfq`}
          component={lazy(() => import("./RFQ"))}
        />
        <Route
          path={`${SELLER_PREFIX_PATH}/profile`}
          component={lazy(() => import("./Profile"))}
        />
        <Route
          path={`${SELLER_PREFIX_PATH}/chat`}
          component={lazy(() => import("./ChatFunctionality"))}
        />
        <Redirect
          from={`${SELLER_PREFIX_PATH}`}
          to={`${SELLER_PREFIX_PATH}/home`}
        />

        {/* <Redirect from={`${SELLER_PREFIX_PATH}`} to={`${SELLER_PREFIX_PATH}/listing`} /> */}
      </Switch>
    </Suspense>
    // </React.StrictMode>
    // </ChatContextProvider>
    // </AuthContextProvider>
    // </ThemeSwitcherProvider>
    // </PersistGate>
    // </Provider>
  );
};

export default React.memo(SellerViews);
