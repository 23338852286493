import { createContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    // const unsub = onAuthStateChanged(auth, (user) => {
    //   console.log("what is user--?", user);
    //   setCurrentUser(user);
    //   console.log("AuthContextProvider->user->", user);
    // });

    // return () => {
    unsub();
    
    // };
  }, []);

console.log("AuthContextProvider=>auth->",auth);

  const unsub = onAuthStateChanged(auth, (user) => {
    console.log("what is user--?", user);
    setCurrentUser(user);
    console.log("AuthContextProvider->user->", user);
  });

  console.log("before return-->currentUser-->", currentUser);

  return (
    // console.log("AuthContextProvider->currentUser-->", currentUser),
    // (
    //   <AuthContext.Provider value={{ currentUser }}>
    //     {children}
    //   </AuthContext.Provider>
    // )
    <></>
  );
};
