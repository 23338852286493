import React, { useEffect, useState } from "react";
import {
  Menu,
  Dropdown,
  Avatar,
  Tooltip,
  Button,
  Popconfirm,
  Modal,
} from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { fetchuser } from "redux/actions/User";
import { useHistory } from "react-router-dom";
import { store } from "redux/store";
import { constant } from "lodash";

export const NavProfile = (props) => {
  const { signOut, fetchuser, userDetails } = props;

  console.log("UserDetails", userDetails);
  const [userTypeId, setUserTypeId] = useState();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const menuItem = [
    {
      title: "Edit Profile",
      icon: EditOutlined,
      path:
        userTypeId === 1
          ? "/app/pages/profile"
          : userTypeId === 2
          ? "/buyer/profile"
          : "/seller/profile",
    },

    // {
    //   title: "Account Setting",
    //   icon: SettingOutlined,
    //   // path: "/",
    // },
    // {
    //   title: "Billing",
    //   icon: ShopOutlined,
    //   path: "/",
    // },
    // {
    //   title: "Help Center",
    //   icon: QuestionCircleOutlined,
    //   path: "/",
    // },
  ];

  // console.log("userDetails", userDetails.UserTypeId);

  // if (userDetails) {
  //   setUserTypeId(userDetails.UserTypeId);
  // }

  const userRef = store.getState().auth.userInfo.UserRef;

  const values = { UserRef: userRef };

  const onSignOut = () => {
    console.log("inside signOut");
    signOut(values, (res) => {
      console.log("res-->", res);
      if (!res.exists) {
        history.push("/auth/login");
      }
    });
  };

  useEffect(() => {
    console.log("NavProfile");
    fetchuser(userRef, (res) => {
      console.log("res-->", res);

      setUserTypeId(res.airports.UserTypeId);
    });
  }, []);

  console.log("userTypeId", userTypeId);

  const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} icon={<UserOutlined />} />

          <div className="pl-3">
            <h4 className="mb-0">{userDetails["Name"]}</h4>
            <span className="text-muted">{userDetails["UserType"]}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* {menuItem.map((el, i) => {
						return (
							<Menu.Item key={i}>
								<div
									onClick={() => {
										history.push(el.path);
									}}
								>
									<Icon type={el.icon} />
									<span className="font-weight-normal">{el.title}</span>
								</div>
							</Menu.Item>
						);
					})} */}
          <Menu.Item key={menuItem.length + 1} onClick={onSignOut}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    // <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
    //   <Menu className="d-flex align-item-center" mode="horizontal">
    //     <Menu.Item key="profile">
    //       <Avatar size={45} icon={<UserOutlined />} />
    //     </Menu.Item>
    //   </Menu>
    // </Dropdown>
    <div style={{ overflow: "auto" }}>
      <div>
        <Popconfirm
          title="Are you sure you want to Sign Out?"
          onCancel={onSignOut}
          open={open}
          onConfirm={() => setOpen(false)}
          okType="default"
          okText="No"
          cancelText="Yes"
          placement="bottomRight"

          // okButtonProps={back}
        >
          <Tooltip title="Sign Out">
            <Button
              type="default"
              // style={{ color: "black", backgroundColor: "#FFB200" }}
              icon={<LogoutOutlined />}
              onClick={() => setOpen(true)}
            ></Button>
          </Tooltip>
        </Popconfirm>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const { userDetails } = user;
  return {
    userDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: (data, callback) => dispatch(signOut(data, callback)),
  fetchuser: (data, callback) => dispatch(fetchuser(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
