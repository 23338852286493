import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
// import "swiper/css/bundle";
// import "./styles.css";
// import Home from "landing-page/pages/Home";

// import Slider from "landing-page/components/Slider";
// import LandingPage from "landing-page/pages/LandingPage";
// import Features from "landing-page/pages/Features";
// import HowItWorks from "landing-page/pages/HowItWorks";
// import Categories from "landing-page/pages/Categories";
// import Feedback from "landing-page/pages/Feedback";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
