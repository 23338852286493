import { Button, Card, Divider, Row, Space, Typography } from "antd";
import { CalendarOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory } from "react-router-dom";

const { Title, Paragraph } = Typography;
const Feed = () => {
  let history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space
        direction="vertical"
        size="small"
        style={{
          fontFamily: "Oswald",
          marginBottom: "10%",
          marginTop: "10%",
          padding: "20px",
          display: "flex",
          height: "100%",
        }}
        align="center"
      >
        <div
        // style={{ width: "100%" }}
        >
          {/* <Title
            level={1} */}
          <h1
            style={{
              color: "white",
              fontWeight: "1000",
              fontSize: "40px",
              // fontStretch: "condensed",
            }}
          >
            On Demand Manufacturing
          </h1>
          <Divider style={{ borderColor: "#f7cb0c" }} />
        </div>
        {/* <Title level={3} style={{ color: "#f7cb0c" }}>
          Lorem ipsum dolor sit
        </Title> */}
        {/* <Title level={1} style={{ color: "white" }}>
          Lorem ipsum dolor sit amet consectetur. Dicta maiores consequatur.
        </Title> */}
        <Paragraph level={5} style={{ color: "white" }}>
          With a large number of manufacturers in India, our network has the
          capacity to meet your requirements of production!
        </Paragraph>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <CalendarOutlined />
            <div style={{ marginLeft: "10px" }}>Parts in as Fast as 3 Days</div>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SafetyCertificateOutlined />

            <div style={{ marginLeft: "10px" }}>
              Strong QA, AA Certified, IATF Certified
            </div>
          </div>
        </div>
        {/* <Button
          size="large"
          style={{
            // fontFamily: "Maison Neue Bold",
            borderRadius: "10px",
            color: "black",
            backgroundColor: "#f7cb0c",
            fontSize: "18px",
          }}
          onClick={() => {
            history.push("/auth/login");
          }}
        >
          SIGN IN
        </Button> */}
      </Space>
    </div>
  );
};

export default Feed;
