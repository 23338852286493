import React from "react";
import { connect } from "react-redux";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/buyer-seller-layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import { Layout, Grid } from "antd";

import sellerNavConfig from "configs/SellerNavConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import SellerViews from "views/seller-views";
import SideNav from "components/layout-components/SideNav";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const SellerLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
}) => {
  const currentRouteInfo = utils.getRouteInfo(
    sellerNavConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    console.log("isNavTop", isNavTop),
    (
      <Layout>
        <HeaderNav isMobile={isMobile} layout="seller" />
        {/* <TopNav routeInfo={currentRouteInfo} layout="seller" /> */}
        {/* className="my-5" was removed */}
        <div>
          <Layout className="app-container">
            <Layout className="app-container">
              {isNavSide && !isMobile ? (
                <SideNav routeInfo={currentRouteInfo} layout="seller" />
              ) : null}
              {/* <Layout
              className="app-layout my-5 "
              // style={getLayoutDirectionGutter()}
            > */}
              <Layout className="app-layout" style={getLayoutDirectionGutter()}>
                <div
                  className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}
                >
                  {/* <PageHeader
                  display={currentRouteInfo?.breadcrumb}
                  title={currentRouteInfo?.title}
                /> */}
                  <Content>
                    <SellerViews />
                  </Content>
                </div>
                <Footer />
              </Layout>
            </Layout>
          </Layout>
        </div>
        {isMobile && <MobileNav routeInfo={currentRouteInfo} layout="seller" />}
      </Layout>
    )
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(SellerLayout));
