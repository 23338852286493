import { Button, Col, Divider, Row, Typography } from "antd";
import React, { useState } from "react";
import PageButton from "./PageButton";
const { Title, Paragraph } = Typography;
const RightBar = ({ number, handleClick, home, buyer, seller, ourworks }) => {
  return (
    <div style={{ height: "300px", fontFamily: "Oswald" }}>
      <Col type="flex" justify="end" align="middle">
        {/* <PageButton number="01" />
      <PageButton number="02" />
      <PageButton number="03" />
      <PageButton number="04" />
      <PageButton number="05" /> */}
        {/* <Divider style={{ borderColor: "#f7cb0c" }} /> */}
        <Row>
          <Button
            className="page-button"
            style={{
              border: "none",
              borderColor: "#12111f",
              marginRight: "20px",
              // padding: "15px",
              // display: "flex",
              // alignItems: "center",
            }}
            size="large"
            type="link"
            onClick={() => handleClick(home)}
          >
            <Title
              level={2}
              style={{
                color: number == "01" ? "#f7cb0c" : "white",
                fontWeight: "500",
              }}
            >
              01
            </Title>
          </Button>
        </Row>
        <Row>
          <Button
            className="page-button"
            style={{
              border: "none",
              borderColor: "#12111f",
              marginRight: "20px",
              // padding: "15px",
              // display: "flex",
              // alignItems: "center",
            }}
            size="large"
            type="link"
            onClick={() => handleClick(buyer)}
          >
            <Title
              level={2}
              style={{
                color: number == "02" ? "#f7cb0c" : "white",
                fontWeight: "500",
              }}
            >
              02
            </Title>
          </Button>
        </Row>
        <Row>
          <Button
            className="page-button"
            style={{
              border: "none",
              borderColor: "#12111f",
              marginRight: "20px",
              // padding: "15px",
              // display: "flex",
              // alignItems: "center",
            }}
            size="large"
            type="link"
            onClick={() => handleClick(seller)}
          >
            <Title
              level={2}
              style={{
                color: number == "03" ? "#f7cb0c" : "white",
                fontWeight: "500",
              }}
            >
              03
            </Title>
          </Button>
        </Row>

        <Row>
          <Button
            className="page-button"
            style={{
              border: "none",
              borderColor: "#12111f",
              marginRight: "20px",
              // padding: "15px",
              // display: "flex",
              // alignItems: "center",
            }}
            size="large"
            type="link"
            onClick={() => handleClick(ourworks)}
          >
            <Title
              level={2}
              style={{
                color: number == "04" ? "#f7cb0c" : "white",
                fontWeight: "500",
              }}
            >
              04
            </Title>
          </Button>
        </Row>
        {/* <Row>
          <Button
            className="page-button"
            style={{
              border: "none",
              borderColor: "#12111f",
              marginRight: "20px",
              // padding: "15px",
              // display: "flex",
              // alignItems: "center",
            }}
            size="large"
            type="link"
            onClick={() => handleClick(feedback)}
          >
            <Title
              level={2}
              style={{
                color: number == "05" ? "#f7cb0c" : "white",
                fontWeight: "500",
              }}
            >
              05
            </Title>
          </Button>
        </Row> */}
      </Col>
    </div>
  );
};

export default RightBar;
