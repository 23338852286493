import {
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Space,
  Tooltip,
} from "antd";
import React from "react";

// import "antd/dist/antd.css";
import "../styles/main.css";
import logo from "../images/logo-light.png";
import Feedback from "../pages/Feedback";
import {
  SmileOutlined,
  UnorderedListOutlined,
  ArrowLeftOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const Header = ({
  handleClick,
  home,
  buyer,
  seller,
  ourworks,
  setActive,
  active,
}) => {
  let history = useHistory();

  return (
    <>
      <div className="header" style={{ fontFamily: "Oswald" }}>
        <Row
          align="middle"
          justify="space-between"
          style={{ width: "100%", display: "inline-flex" }}
        >
          <Col lg={{ span: 4, offset: 0 }}>
            <img src={logo} alt="LOGO" />
          </Col>
          <Col xs={0} sm={0} md={0} lg={20} xl={20}>
            <Row justify="space-around">
              <Col span={3}>
                <Button
                  className="antd-btn"
                  onClick={() => {
                    handleClick(home);
                    // setActive({
                    //   home: true,
                    //   buyer: false,
                    //   seller: false,
                    //   ourworks: false,
                    // });
                  }}
                  style={{
                    color: active.home ? "#f7cb0c" : "#fff",
                    fontSize: "20px",
                  }}
                  type="link"
                >
                  HOME
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  className="antd-btn"
                  onClick={() => {
                    handleClick(buyer);
                    // setActive({
                    //   home: false,
                    //   buyer: true,
                    //   seller: false,
                    //   ourworks: false,
                    // });
                  }}
                  style={{
                    color: active.buyer ? "#f7cb0c" : "#fff",
                    fontSize: "20px",
                  }}
                  type="link"
                >
                  ARE YOU BUYING?
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  className="antd-btn"
                  onClick={() => {
                    handleClick(seller);
                    // setActive({
                    //   home: false,
                    //   buyer: false,
                    //   seller: true,
                    //   ourworks: false,
                    // });
                  }}
                  style={{
                    color: active.seller ? "#f7cb0c" : "#fff",
                    fontSize: "20px",
                  }}
                  type="link"
                >
                  ARE YOU SELLING?
                </Button>
              </Col>

              <Col span={3}>
                <Button
                  className="antd-btn"
                  onClick={() => {
                    handleClick(ourworks);
                    // setActive({
                    //   home: false,
                    //   buyer: false,
                    //   seller: false,
                    //   ourworks: true,
                    // });
                  }}
                  style={{
                    color: active.ourworks ? "#f7cb0c" : "#fff",
                    fontSize: "20px",
                  }}
                  type="link"
                >
                  OUR WORKS
                </Button>
              </Col>

              {/* <Col span={3}>
                <Button
                  className="antd-btn"
                  onClick={() => handleClick(feedback)}
                  style={{ color: "#fff", fontSize: "20px" }}
                  type="link"
                >
                  FEEDBACK
                </Button>
              </Col> */}
              <Col span={3}>
                <Button
                  // className="submit-btn"
                  style={{
                    // fontFamily: "Maison Neue Bold",
                    borderRadius: "10px",
                    color: "black",
                    backgroundColor: "#f7cb0c",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    history.push("/auth/login");
                  }}
                >
                  SIGN IN
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={20} sm={20} md={20} lg={0} xl={0} flex="auto">
            <div className="dropdown" style={{ float: "right" }}>
              <Button
                className="drop-btn"
                icon={
                  <UnorderedListOutlined
                    style={{
                      justifyContent: "right",
                      display: "flex",
                      color: "white",
                      fontSize: "30px",
                      fontWeight: "700",
                      // padding: "10px",
                    }}
                  />
                }
                style={{
                  borderColor: "#12111f",
                }}
                type="text"
              >
                <div className="dropdown-content">
                  <button
                    onClick={() => {
                      handleClick(home);
                      // setActive({
                      //   home: true,
                      //   buyer: false,
                      //   seller: false,
                      //   ourworks: false,
                      // });
                    }}
                  >
                    HOME
                  </button>
                  <button
                    onClick={() => {
                      handleClick(buyer);
                      // setActive({
                      //   home: false,
                      //   buyer: true,
                      //   seller: false,
                      //   ourworks: false,
                      // });
                    }}
                  >
                    BUYER
                  </button>
                  <button
                    onClick={() => {
                      handleClick(seller);
                      // setActive({
                      //   home: false,
                      //   buyer: false,
                      //   seller: true,
                      //   ourworks: false,
                      // });
                    }}
                  >
                    SELLER
                  </button>
                  <button
                    onClick={() => {
                      handleClick(ourworks);
                      // setActive({
                      //   home: false,
                      //   buyer: false,
                      //   seller: false,
                      //   ourworks: true,
                      // });
                    }}
                  >
                    OUR WORKS
                  </button>
                  <button onClick={() => history.push("/auth/LOGIN")}>
                    SIGN IN
                  </button>
                </div>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Header;
