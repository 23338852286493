export const FETCHUSER = "FETCHUSER";
export const ADDUSER = "ADDUSER";
export const FETCHALLUSER = "FETCHALLUSER";
export const FETCHBLACKLISTEDUSER = "FETCHBLACKLISTEDUSER";
export const FIREBASEUSER = "FIREBASEUSER";
export const STOREFIREBASEUSER = "STOREFIREBASEUSER";
export const CHATWITHSELLER = "CHATWITHSELLER";
export const CHATWITHBUYER = "CHATWITHBUYER";
export const SAVEFIREBASEUSERID = "SAVEFIREBASEUSERID";
export const NDAUPLOADFLAG = "NDAUPLOADFLAG";
export const REFERENCEIMAGESFLAG = "REFERENCEIMAGESFLAG";
export const VALIDATEUSER = "VALIDATEUSER";
