import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  USER_LOGIN,
} from "../constants/Auth";

import { ADDCATEGORY } from "../constants/Categories";

import API from "services/API/API";
import { store } from "redux/store";
// const token = store.getState().auth.userInfo.AuthToken;

export const loginUser = (data, callback) => (dispatch) => {
  //  console.log('data===>', data);
  API.post("user/userlogin", data).then((response) => {
    console.log("response", response.data);
    if (response.data) {
      sessionStorage.setItem("token", response?.data?.Details?.AuthToken);
      dispatch({ type: USER_LOGIN, payload: response?.data });
      callback({
        exists: false,
        airports: response?.data,
        error: response?.data.Error?.ErrorMessage,
      });
    }
  });
};

// export const loginUser = (data, callback) => (dispatch) => {
//   API.post(`user/userlogin`, data).then((response) => {
//     console.log("response", response.data);
//     if (response.data) {
//       if (response.data.Details != null) {
//         sessionStorage.setItem("token", response?.data?.Details?.AuthToken);
//         dispatch({ type: USER_LOGIN, payload: response?.data });
//         callback({
//           exists: false,
//           airports: response?.data,
//         });
//       } else if (response.data.Error) {
//         callback({ exists: true, error: response.data.Error.ErrorMessage });
//       }
//     }
//   });
// };

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  };
};

export const signOut = (data, callback) => (dispatch) => {
  console.log("inside signout api integration");
  console.log("userinfo-->", store.getState().auth.userInfo);

  const token = store.getState().auth.userInfo.AuthToken;
  console.log("token--->", token);

  API.post("user/userlogout", data, { headers: { AuthToken: token } }).then(
    (response) => {
      console.log("response", response);
      if (response.data.Details) {
        dispatch({ type: SIGNOUT });
        dispatch({ type: SIGNOUT_SUCCESS });
        callback({ exists: false });
      }
    }
  );
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (data, callback) => (dispatch) => {
  API.post("user/signup", data).then((res) => {
    console.log("signUp--->", res);
    if (res.data.Details != null) {
      dispatch({ type: SIGNUP });
      callback({ exists: false, airports: res.data.Details });
    } else if (res.data.Error != null) {
      callback({ exists: true, error: res.data.Error.ErrorMessage });
    }
  });
};

export const signUpSuccess = () => {
  return {
    type: SIGNUP_SUCCESS,
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE,
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token,
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK,
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token,
  };
};

export const showAuthMessage = (message) => (dispatch) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = (callback) => (dispatch) => {
  return {
    type: SHOW_LOADING,
    callback,
  };
};

export const addCategory = (data, callback) => (dispatch) => {
  const token = store.getState().auth.userInfo.AuthToken;
  console.log("token--->", token);
  console.log("data--->", data);
  console.log("inside addCategory");
  API.post(`/category/addcategory`, data, {
    headers: { AuthToken: token },
  }).then((res) => {
    console.log("addCategory res --->", res);
    if (res.data?.Details) {
      dispatch({ type: ADDCATEGORY });
      callback({ exists: false, airports: res.data?.Details });
    }
  });
};
