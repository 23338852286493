import {
  EnvironmentOutlined,
  BookOutlined,
  HomeOutlined,
  DollarOutlined,
  SettingOutlined,
  LogoutOutlined,
  DashboardOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Popconfirm } from "antd";
import { BUYER_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";

const buyerHomeNavTree = [
  {
    key: "home",
    path: `${BUYER_PREFIX_PATH}/home`,
    title: "HOME",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "home-dashboard",
        path: `${BUYER_PREFIX_PATH}/home`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "home-profile",
        path: `${BUYER_PREFIX_PATH}/profile`,
        title: "Profile",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  // {
  //   key: "payment",
  //   path: `${BUYER_PREFIX_PATH}/docs`,
  //   title: "SUBSCRIPTION",
  //   icon: DollarOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "payment-subscription",
  //       path: `${BUYER_PREFIX_PATH}/docs/documentation`,
  //       title: "Payment",
  //       icon: DollarOutlined,
  //       breadcrumb: false,
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   key: "settings",
  //   path: `${AUTH_PREFIX_PATH}/login`,
  //   title: "SETTINGS",
  //   icon: SettingOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "settings-logout",
  //       path: `${AUTH_PREFIX_PATH}/login`,
  //       title: "Sign Out",
  //       icon: LogoutOutlined,
  //       breadcrumb: false,
  //       submenu: [],
  //     },
  //   ],
  // },
];
const buyerScreenTree = [
  {
    key: "rfq",
    path: `${BUYER_PREFIX_PATH}/rfq`,
    title: "RFQ",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "uploadrfq",
        path: `${BUYER_PREFIX_PATH}/rfq/uploadRFQ`,
        title: "Upload RFQ",
        icon: EnvironmentOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: "seller",
    path: `${BUYER_PREFIX_PATH}/seller`,
    title: "Seller",
    icon: BookOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "SellerList",
        path: `${BUYER_PREFIX_PATH}/seller/sellerlist`,
        title: "Seller List",
        icon: EnvironmentOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: "listingQuotation",
    path: `${BUYER_PREFIX_PATH}/quotation`,
    title: "Quotation",
    icon: BookOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "ListingQuotation",
        path: `${BUYER_PREFIX_PATH}/quotation/quotationListing`,
        title: "Quotation List",
        icon: EnvironmentOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

// const buyerNavConfig = [...buyerScreenTree];
const buyerNavConfig = [...buyerHomeNavTree];
export default buyerNavConfig;
