import React from "react";
import { Divider, Typography } from "antd";
// import title from "landing-page/styles/title";

const { Title, Paragraph } = Typography;
const Description = (props) => {
  return (
    <>
      <div className="card-products-pc" style={{ fontFamily: "Oswald" }}>
        <div className="overlay-img">
          <img src={props.img} alt="Alternate"></img>
        </div>
        <div
          className="card"
          style={{
            // marginTop:
            padding: "10px",
            fontFamily: "Montserrat ( Extrabold)",
          }}
        >
          <div style={{ marginTop: "100px" }}>
            <Title level={4} style={{ color: "#f7cb0c" }}>
              PRODUCT NAME
            </Title>

            <h6 style={{ color: "white" }}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempora,
              quis et sint corrupti cupiditate quidem, assumenda eveniet nobis,
              recusandae rerum laudantium?
            </h6>
          </div>
        </div>
      </div>
      {/* <div className="card-products-mobile ">
        <div>
          <img src={props.card} alt="Alternate"></img>
        </div>
      </div> */}
    </>
  );
};

export default Description;
