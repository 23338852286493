import { Carousel, Col, Row, Typography } from "antd";
import Description from "../components/Description";
import Feed from "../components/Feed";
import Header from "../components/Header";
import TextBox from "../components/TextBox";
import MediaButton from "../components/MediaButton";

import RightBar from "../components/RightBar";
import SideBar from "../components/SideBar";
import React from "react";
import bars1 from "../images/bars1.png";
import bars2 from "../images/bars2.png";
import screws from "../images/screws.png";
import card1 from "../images/card1.png";
import card2 from "../images/card2.png";
import card3 from "../images/card3.png";

import "../styles/main.css";

const { Title } = Typography;
const contentStyle = {
  //   height: "80px",
  // padding: "20px",
  color: "#fff",
  //   lineHeight: "160px",
  // textAlign: "center",
  // background: "#364d79",
};
const Categories = ({
  handleClick,
  home,
  feedback,
  categories,
  features,
  howitworks,
}) => {
  return (
    <div className="products" ref={categories}>
      <Row
        // gutter={[16, 24]}
        type="flex"
        justify="center"
        align="middle"
        style={{ height: "100vh" }}

        // style={{ backgroundColor: "pink" }}
      >
        <Col
          xl={{ span: 4, order: 1 }}
          lg={{ span: 4, order: 1 }}
          xs={0}
          sm={0}
          md={{ span: 0 }}
        >
          <SideBar />
        </Col>
        <Col
          xl={{ span: 6, order: 2 }}
          lg={{ span: 6, order: 2 }}
          xs={{ span: 24, order: 1 }}
          sm={{ span: 12, order: 1 }}
          md={{ span: 12, order: 1 }}
        >
          <TextBox page="03" />
        </Col>
        <Col
          xl={{ span: 4, order: 3 }}
          lg={{ span: 0, order: 3 }}
          xs={{ span: 0, order: 2 }}
          sm={{ span: 0, order: 2 }}
          md={{ span: 0 }}
        >
          {/* <div className="product-img">
            <Description img={screws} card={card3} />
          </div> */}
          <div>
            <img
              className="card-products-mobile"
              src={card1}
              alt="screws"
            ></img>
          </div>
        </Col>
        <Col
          xl={{ span: 4, order: 3 }}
          lg={{ span: 0, order: 3 }}
          xs={{ span: 0, order: 3 }}
          sm={{ span: 0, order: 3 }}
          md={{ span: 0 }}
        >
          {/* <div className="product-img">
            <Description img={bars2} card={card2} />
          </div> */}
          <div>
            <img
              className="card-products-mobile"
              src={card2}
              alt="screws"
            ></img>
          </div>
        </Col>
        <Col
          xl={{ span: 4, order: 4 }}
          lg={{ span: 0, order: 3 }}
          xs={{ span: 0, order: 4 }}
          sm={{ span: 0, order: 4 }}
          md={{ span: 0 }}
        >
          {/* <div className="product-img">
            <Description img={bars1} card={card1} />
          </div> */}
          <div>
            <img
              className="card-products-mobile"
              src={card3}
              alt="screws"
            ></img>
          </div>
        </Col>
        <Col
          xl={0}
          lg={{ span: 12, order: 3 }}
          md={{ span: 12, order: 2 }}
          xs={{ span: 24, order: 2 }}
          sm={{ span: 12, order: 2 }}
          flex="auto"
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              // backgroundColor: "blue",
            }}
          >
            <Carousel autoplay className="card-products-mobile">
              <div>
                <img
                  className="card-products-mobile"
                  src={card1}
                  alt="screws"
                ></img>
              </div>
              <div>
                <img
                  className="card-products-mobile"
                  src={card2}
                  alt="screws"
                ></img>
              </div>
              <div>
                <img
                  className="card-products-mobile"
                  src={card3}
                  alt="screws"
                ></img>
              </div>
            </Carousel>
          </div>
        </Col>
        {/* <Col
        xl={{ span: 0 }}
        lg={{ span: 5, order: 3 }}
        md={{ span: 6, order: 2 }}
        sm={{ span: 6, order: 2 }}
        xs={{ span: 10, order: 2 }}
        > */}
        {/* <Carousel autoplay>
            <div style={contentStyle}>
              <img
                src={card1}
                className="card-products-mobile"
                alt="Alternate"
              ></img>{" "}
            </div>
            <div style={contentStyle}>
              <img
                src={card2}
                className="card-products-mobile"
                alt="Alternate"
              ></img>{" "}
            </div>
            <div style={contentStyle}>
              <img
                src={card3}
                className="card-products-mobile"
                alt="Alternate"
              ></img>{" "}
            </div>
          </Carousel> */}
        {/* </Col> */}
        <Col
          xxl={1}
          xl={{ span: 2, order: 5 }}
          lg={{ span: 2, order: 4 }}
          xs={0}
          sm={0}
          md={{ order: 4 }}
        >
          <RightBar
            number={"03"}
            handleClick={handleClick}
            home={home}
            feedback={feedback}
            categories={categories}
            features={features}
            howitworks={howitworks}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Categories;
