import { Button, Card, Divider, Row, Space, Typography } from "antd";
import React from "react";
import SignInButton from "./SignInButton";

const { Title, Paragraph } = Typography;
const TextBox = (props) => {
  return (
    <div>
      <Space
        direction="vertical"
        size="small"
        style={{
          padding: "20px",
          display: "flex",
          fontFamily: "Oswald",
          fontSize: "18px",
        }}
      >
        <div style={{ width: "50px" }}>
          <Title level={1} style={{ color: "white", fontWeight: "1000" }}>
            {props.page}
          </Title>
          <Divider style={{ borderColor: "#f7cb0c" }} />
        </div>
        <Title level={2} style={{ color: "#f7cb0c" }}>
          Lorem ipsum dolor sit
        </Title>

        <Paragraph level={5} style={{ color: "white" }}>
          Lorem ipsum <br />
          Lorem ipsum <br />
          Lorem ipsum <br />
          Lorem ipsum <br />
        </Paragraph>
        {/* <SignInButton /> */}
      </Space>
    </div>
  );
};

export default TextBox;
